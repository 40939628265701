import * as React from "react"
import { Link } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../../../components/Layout"
import "bootstrap/dist/css/bootstrap.min.css"
import styled from "styled-components"
import Prodopt from "../../../components/Prodopt"
import Prodfeat from "../../../components/Prodfeat"


import { Container, Nav, Card, CardImg, CardProps, CardImgProps } from "react-bootstrap"

const StyledHome = styled.div`
  display: flex;
  gap: 0rem;
  position: absolute;
  flex-direction: row;
  margin: 0 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
`;
const Page1 = ({ }) => (
    <Layout>
        <Container>
            <div className="column">
            <br /><br />
                <div class="text-center">
                    <StaticImage
                        src="../img/pertum.png"
                        width={790}
                        quality={50}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="pertum"

                    />
                </div>



                <h1 class="text-center">СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР</h1>
                <p class="blockquote card px-4 mx-5 py-3">Серия Ozdoken Pertum зерновая сеялка с вариатором</p>
                <h2 class="text-center py-3 text-black-50">
                    ПРЕИМУЩЕСТВА ПРОДУКТА
                </h2>
                <div class="row justify-content-center text-center px-5">
                <Prodfeat text="ОДИНАРНАЯ ДИСКОВАЯ СЕКЦИЯ" />
                <Prodfeat text="ДВУХДИСКОВАЯ СЕКЦИЯ" />
                <Prodfeat text="РЕГУЛИРОВАНИЕ ДАВЛЕНИЯ И ГЛУБИНЫ" />
                <Prodfeat text="ВАРИАТОР КОРОБКА" />
                <Prodfeat text="ДВОЙНЫЕ ГИДРАВЛИЧЕСКИЕ МАРКЕРЫ" />
                <Prodfeat text="ДОЗАТОР ДЛЯ ВСЕХ ВИДОВ ЗЕРНА" />
                <Prodfeat text="БОРОНА ПОКРЫТИЯ СЕМЯН" />
                <Prodfeat text="6.00x16 ШИНА" />
                <Prodfeat text="10.0/75-15.3 ШИНА" />


                </div>
                <div class="row">



                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"> 

                        <StaticImage src="img/637261714254935567-b75-----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР. " style={{ marginBottom: `1.45rem` }} />
                     <strong
                        class="h4">РЕГУЛИРОВАНИЕ ДАВЛЕНИЯ И ГЛУБИНЫ</strong>
                        <p class="h5">Давления можно отрегулировать механически. </p> </div> </div> </div>

                        {/* <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"> 
                        <StaticImage src="img/637261720958846035-b75--550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР. " style={{ marginBottom: `1.45rem` }} />
                     <strong
                        class="h4">СЛЕДОЗАДЕЛЫВАТЕЛЬ</strong>
                        <p class="h5">Следозаделыватели можно оснащать опционально чтоб потерять следа
                            трековые колесо. </p> </div> </div> </div> */}


                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"> 
                        <StaticImage src="img/637261717868471746-b75---3--550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР. " style={{ marginBottom: `1.45rem` }} />

                    
                        <strong class="h4">РАСШИРЕНИЕ ДЛЯ 3-ТОЧЕЧНОЙ СОЕДИНЕНИЕ</strong>
                        <p class="h5"> </p> </div> </div> </div><div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark">  <StaticImage src="img/637261711334238410-b75----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР. " style={{ marginBottom: `1.45rem` }} />
                         <strong
                            class="h4">ОДИНАРНАЯ ДИСКОВАЯ СЕКЦИЯ</strong>
                            <p class="h5">По Стандарту имеет один диск с прессно - посевным колесом которое
                                обеспечивает точную глубину посева даже маленьких семян. </p> </div> </div> </div>

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"> 
                        <StaticImage src="img/637261712954411361-b75----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР. " style={{ marginBottom: `1.45rem` }} />

                     <strong
                        class="h4">ПАЛЬЦЕВЫЙ ЗАГОРТАЧ</strong>
                        <p class="h5"> </p> </div> </div> </div>


                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"> 

                        <StaticImage src="img/637261715986472464-b75-600x16--550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР. " style={{ marginBottom: `1.45rem` }} />
                     <strong
                        class="h4">6.00x16 ШИНА</strong>
                        <p class="h5">Доступны рабочие ширины 250 и 300 см. </p> </div> </div> </div>

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"> 

                        <StaticImage src="img/637261717135637143-b75----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР. " style={{ marginBottom: `1.45rem` }} />
                     <strong
                        class="h4">ДВОЙНЫЕ ГИДРАВЛИЧЕСКИЕ МАРКЕРЫ</strong>
                        <p class="h5">По Стандарту Все модели Pertum имеют двойные гидравлические маркеры.
                        </p> </div> </div> </div>



                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"> 
                        <StaticImage src="img/637261719523128636-b75---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР. " style={{ marginBottom: `1.45rem` }} />

                     <strong
                        class="h4">УВЕЛИЧЕНИЕ БУНКЕРА</strong>
                        <p class="h5">У всех Pertum моделей есть опциональный комплект для дополнительной
                            вместимости. </p> </div> </div> </div >





                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"> 
                        <StaticImage src="img/637261712324336789-b75----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР. " style={{ marginBottom: `1.45rem` }} />

                     <strong
                        class="h4">ДВОЙНАЯ ДИСКОВАЯ СЕКЦИЯ С ПРИКАТЫВАЮЩИМИ КОЛЕСАМИ</strong>
                        <p class="h5">
                            </p> </div> </div> </div >

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"> 
                        <StaticImage src="img/637261713679904682-b75------550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР. " style={{ marginBottom: `1.45rem` }} />

                     <strong
                        class="h4">ДОЗАТОР ДЛЯ ВСЕХ ВИДОВ ЗЕРНА</strong>
                        <p class="h5">Дозатор имеет 2 типа ролика для посева семян мелких и нормальных
                            размеров. Смена семян очень проста без смены ролика. </p> </div> </div> </div >

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"> 
                        <StaticImage src="img/637261715272631541-b75---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР. " style={{ marginBottom: `1.45rem` }} />

                     <strong
                        class="h4">ВАРИАТОР КОРОБКА</strong>
                        <p class="h5">С
                            помощью переменной коробки передачи можно сеять семена от 1,5 кг до 400
                            кг на гектар. Дозиметр можно легко регулировать согласно размеру семян. </p> </div> </div> </div >

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"> 
                        <StaticImage src="img/637261716508144049-b75-10075153--550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР. " style={{ marginBottom: `1.45rem` }} />

                     <strong
                        class="h4">10.0/75-15.3 ШИНА</strong>
                        <p class="h5">Доступны рабочие ширины 350 и 400 см. </p> </div> </div> </div >

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"> 
                        <StaticImage src="img/637261710360497967-b75---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР. " style={{ marginBottom: `1.45rem` }} />
                     <strong
                        class="h4">КОМПЛЕКТ УДОБРЕНИЙ</strong>
                        <p class="h5">Все модели Pertum имеют опцию бункера для удобрений. </p> </div> </div> </div >

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"> 
                        <StaticImage src="img/637261718596638523-b75-------550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР. " style={{ marginBottom: `1.45rem` }} />
                     <strong
                        class="h4">СИСТЕМА КОНТРОЛЯ ДЛЯ ИСКЛЮЧЕНИЯ ТЕХНОЛОГИЧЕСКОЙ КОЛЕИ</strong>
                        <p class="h5">Система контроля позволяет автоматически закрывать дозаторы для технологической колеи для опрыскивателя </p> </div> </div> </div >

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"> 
                        <StaticImage src="img/637261720235806213-b75----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР. " style={{ marginBottom: `1.45rem` }} />
                     <strong
                        class="h4"></strong>
                        <p class="h5">Система контроля с расширенными функциями: -Счетчик
                            Площади -Скорость Высева -Показатель уровня семян и удобрений
                            -Управление измерительным валом </p> </div> </div> </div>



                </div >
                <h2 class="text-center py-3 text-black-50">
                ТЕХНИЧЕСКИЕ СПЕЦИФИКАЦИИ
                </h2>
                <div class="table-responsive">
                    <table class="table table-striped table-hover table-bordered"><thead class="background-dark-gray-ozdo"><tr><th>МОДЕЛЬ</th><th>КОЛИЧЕСТВО СЕКЦИЙ (шт)</th><th>РАССТОЯНИЕ МЕЖДУ СЕКЦИЯМИ (cм)</th>
                    <th>РАБОЧАЯ ШИРИНА (cм)</th><th>ТРАНСПОРТНАЯ ШИРИНА (cм)</th><th>РАЗМЕР ШИН</th><th>ВЕС (кг)</th>
                    <th>ЕМКОСТЬ БУНКЕРА ДЛЯ СЕМЯН (л)</th><th>ЕМКОСТЬ БУНКЕРА ДЛЯ СЕМЯН С РАСШИРЕНИЕМ (л)</th>
                    <th>ЕМКОСТЬ БУНКЕРА ДЛЯ УДОБРЕНИЙ (л)</th><th>ЕМКОСТЬ БУНКЕРА ДЛЯ УДОБРЕНИЙ С РАСШИРЕНИЕМ (л)</th><th colspan="2">ТРЕБУЕМАЯ МОЩНОСТЬ (л.с.) (кВт)</th>
                    </tr></thead><tbody><tr><td>PERTUM&#8209;F400</td><td>32</td><td>12,5</td><td>400</td><td>410</td><td>10.0/75-15.3</td><td>1450</td><td>410</td><td>670</td><td>430</td><td>670</td><td>80-110</td><td>60-82</td></tr></tbody></table>
                </div>
                <div>
                </div>
                <br />
            </div >
        </Container >
    </Layout >
)

export default Page1
